import lightHouseImage from "../assets/images/lighthouse.png";
import lightHouseImageDark from "../assets/images/lighthouse_dark.png";
import ladderImage from "../assets/images/associate_climbing_ladder.png";
import ladderImageDark from "../assets/images/associate_climbing_ladder_dark.png";
import hotAirBalloonImage from "../assets/images/associate_hotairballoon.png";
import hotAirBalloonImageDark from "../assets/images/associate_hotairballoon_dark.png";
import moment from "moment-timezone";
import { COPY } from "./Copy";

// MUI
import { Button, Link, Typography } from "@material-ui/core";

export const DEFAULT_PAGE = 0;

export const ROUTES = {
  LANDING: "",
  CAMPAIGN_LIST: "campaigns",
  CAMPAIGN: "campaigns",
  TEAM: "teams",
  TEMPLATES: "templates",
  MEDIA_LIBRARY: "folders/media",
  MEDIA: "media",
  HELP: "help",
  REPORT: "report",
  FOLDERS: "folders",
};

export const ERROR_TYPES = {
  TOKEN_INVALID: {
    message: "",
    title: "",
    subtitle: "Loading....",
  },
  NETWORK: {
    message: "Network Error",
    title: "Sorry, our server is down",
    subtitle: "We hope it will be back up soon.",
    image: lightHouseImage,
    imageDark: lightHouseImageDark,
    button: COPY.ERROR_PAGE.RELOAD_BUTTON,
  },
  PAGE_NOT_FOUND: {
    message: "",
    title: "Sorry, that page can't be found",
    image: ladderImage,
    imageDark: ladderImageDark,
    button: COPY.ERROR_PAGE.HOME_BUTTON,
  },
  USER_NOT_FOUND: {
    // If user is inactive or not available in the users collection
    title: "Sorry, looks like you don't have access to Spark Notifications",
    subtitle: (
      <Button variant="contained" color="primary" size="large">
        <Link
          color="inherit"
          href="#onboarding_steps"
        >
          Request access
        </Link>
      </Button>
    ),
    image: hotAirBalloonImage,
    imageDark: hotAirBalloonImageDark,
  },
  CAMPAIGN_NOT_FOUND: {
    subtitle: (
      <Typography>
        If you think this is an error, please &nbsp;
        <Link
          href={
            "mailto:SparkNotificationsPr@email.wal-mart.com?subject=Error in accessing a campaign!"
          }
        >
          let us know
        </Link>
      </Typography>
    ),
    title: "Sorry, the campaign can't be found",
    image: ladderImage,
    imageDark: ladderImageDark,
    button: COPY.ERROR_PAGE.HOME_BUTTON,
  },
  FOLDER_NOT_FOUND: {
    subtitle: (
      <Typography>
        If you think this is an error, please &nbsp;
        <Link
          href={
            "mailto:SparkNotificationsPr@email.wal-mart.com?subject=Error in accessing a folder!"
          }
        >
          let us know
        </Link>
      </Typography>
    ),
    title: "Sorry, the folder details can't be found",
    image: ladderImage,
    imageDark: ladderImageDark,
    button: COPY.ERROR_PAGE.HOME_BUTTON,
  },
  FORBIDDEN: {
    subtitle: (
      <Typography>
        If you think this is an error, please &nbsp;
        <Link
          href={
            "mailto:SparkNotificationsPr@email.wal-mart.com?subject=Error in accessing a campaign!"
          }
        >
          let us know
        </Link>
      </Typography>
    ),
    title: "Sorry, you do not have access to this page or resource",
    image: hotAirBalloonImage,
    imageDark: hotAirBalloonImageDark,
    button: COPY.ERROR_PAGE.HOME_BUTTON,
  },
  DEFAULT: {
    message: "Error",
    title: "Sorry, we appear to be running into an issue",
    subtitle: "We hope to have it resolved soon.",
    image: lightHouseImage,
    imageDark: lightHouseImageDark,
    button: COPY.ERROR_PAGE.RELOAD_BUTTON,
  },
};

export const CSV_SIZE_LIMIT_MB = 20;
export const IMAGE_SIZE_LIMIT_MB = 5;
export const IMAGE_LIMIT = 10;
export const DROPZONE_SIZE_ERROR = "file-too-large";
export const DROPZONE_TYPE_ERROR = "file-invalid-type";
export const DROPZONE_FILE_LIMIT = "too-many-files";

export const ERROR_MSG = {
  POST_CAMPAIGN: "There was an error creating your campaign, please try again.",
  PUT_CAMPAIGN: "There was an error saving your campaign, please try again.",
  CAMPAIGN_NAME_TAKEN:
    "Sorry, a campaign with the requested name already exists",
  GET_CAMPAIGN: "Sorry, we couldn't find that campaign.",
  DELETE_CAMPAIGN: "Sorry, there was a problem deleting your campaign.",
  PUT_TEMPLATE: "There was an error saving your template, please try again.",
  GET_TEMPLATE: "Sorry, we couldn't find that template.",
  SELECT_TEMPLATE: "Sorry, there was a problem selecting this template.",
  FILE_SIZE: (sizeLimit) => `File size must not exceed ${sizeLimit}MB.`,
  IMAGE_FILE_SIZE: `File size must not exceed`,
  IMAGE_FILE_LIMIT: `You can only upload up to ${IMAGE_LIMIT} files at a time.`,
  FILES_UPLOAD: "There was an issue uploading one or more of your files.",
  FILE_UPLOAD: "There was an issue uploading your file.",
  INVALID_FILE_TYPE: "File must be a .jpg, .jpeg, .gif or .png.",
  TEST_CAMPAIGN:
    "Sorry, there was an issue sending your test campaign, please try again.",
  POST_TEAM_USER_GENERIC: "There was an error adding user, please try again",
  POST_TEAM_USER_DUPLICATE: "This user is already in the team",
  USER_DOES_NOT_HAVE_ACCESS: "Selected user does not have access to the application.",
  PUT_TEAM_USER: "There was an error updating user role, please try again",
  PUT_TEAM: "There was an error updating the team settings, please try again.",
  DELETE_TEAM_USER: "There was an error removing user, please try again",
  SCHEDULE_CAMPAIGN:
    "There was an error scheduling your campaign, please try again.",
  CANCEL_SCHEDULE:
    "Sorry, there was an issue canceling the schedule, please try again.",
  REMOVE_LAST_USER:
    "A team needs to have at least one owner, please update the team with another owner and try again",
  EDIT_QUEUED_SCHEDULE:
    "Sorry, you cannot edit a campaign while a scheduled execution is sending.",
  DELETE_QUEUED_SCHEDULE:
    "Sorry, you cannot delete a campaign while a scheduled execution is sending.",
  EMAIL_LIMIT_REACHED:
    "A Maximum of 100 emails can be added, please select the “personalized” flow in the previous step in-order to add more",
  INVALID_EMAIL_ID: "Please enter a valid email address",
  EMAIL_ID_ALREADY_ADDED: "This email id has been added already",
  DELETE_IMAGE: "Sorry, there was a problem deleting this image.",
  RENAME_IMAGE: "Sorry, there was a problem renaming this image.",
  DELETE_SAVED_TEMPLATE: "Sorry, there was a problem deleting this template.",
  TEMPLATE_NAME_TAKEN:
    "Sorry, a template with the requested name already exists",
  EMAIL_NOT_FOUND: "We could not find the email in our records",
  POST_SAVED_TEMPLATE:
    "There was an error creating your template, please try again.",
  GET_SAVED_TEMPLATE: "Sorry, we couldn't find that template.",
  PUT_SAVED_TEMPLATE:
    "There was an error saving your template, please try again.",
  DUPLICATE_SAVED_TEMPLATE:
    "There was an error duplicating your template, please try again.",
  POST_TEAM: "There was an error creating the team, please try again",
  POST_TEAM_DUPLICATE:
    "A team is already available with the requested team name.",
  RENAME_TEAM: "There was an error while renaming the team, please try again.",
  FAILED_DELIVERY_REPORT:
    "Sorry, there was an error while loading the failed deliveries.",
  NOT_SENT_DELIVERY_REPORT:
    "Sorry, there was an error while loading the not sent deliveries.",
  MORE_FAILED_DELIVERY:
    "Sorry, there was an error while loading the rest of the data",
  EXPORT_FAILED_DELIVERIES:
    "Sorry, there was an issue while downloading the data",
  GET_TEAM_STATS: "Sorry, there was an error while loading the team stats",
  FAILED_UNIQUE_VIEWS_REPORT: "Sorry, there was an issue in loading unique views",
  FAILED_UNIQUE_CLICKS_REPORT: "Sorry, there was an issue in loading unique clicks",
  FAILED_SENT_RECIPIENT_REPORT: "Sorry, there was an issue in loading sent recipients data",
};

export const SUCCESS_MSG = {
  PUT_CAMPAIGN: "Campaign saved successfully.",
  PUT_TEMPLATE: "Template saved successfully.",
  DUPLICATE_CAMPAIGN: "Campaign duplicated successfully.",
  DELETE_CAMPAIGN: "Campaign deleted successfully.",
  TEST_CAMPAIGN: "Test campaign sent successfully.",
  MOVE_CAMPAIGN: "Campaign moved successfully.",
  POST_TEAM_USER: "User added successfully.",
  PUT_TEAM_USER: "User role updated successfully.",
  PUT_TEAM: "Team settings updated successfully.",
  DELETE_TEAM_USER: "User removed successfully.",
  SCHEDULE_CAMPAIGN: "Campaign scheduled successfully.",
  DELETE_IMAGE: "Image deleted successfully.",
  RENAME_IMAGE: "Image updated successfully.",
  DELETE_SAVED_TEMPLATE: "Template deleted successfully.",
  PUT_SAVED_TEMPLATE: "Template saved successfully.",
  DUPLICATE_SAVED_TEMPLATE: "Template duplicated successfully.",
  POST_TEAM: "Team created successfully.",
  RENAME_TEAM: "You have successfully renamed the team",
};

export const WARNING_MSG = {
  CAMPAIGN_WARNING_DUPLICATE_MESSAGE:
    "If there are personalized or dynamic attributes of the campaign you cloned.\nPlease review your design before sending",
};

export const INFO_MSG = {
  CANCEL_SCHEDULE: "Schedule canceled.",
};

export const SNACK_TYPES = {
  INFO: "info",
  WARN: "warning",
  ERROR: "error",
  SUCCESS: "success",
};

export const SNACK_DURATION = 5000;
export const EDITOR_SNACK_DURATION = 3000;

export const AD_USER = {
  MAIL: "mail",
  DISPLAY_NAME: "displayName",
  UPN: "userPrincipalName",
  GIVEN_NAME: "givenName",
  SURNAME: "surname",
  ROLE_ID: "roleId",
  TYPE: "type",
};

export const USER_TYPE = {
  GROUP: "group"
}

export const LOCAL_STORAGE = {
  RECENT_TEAM_ID: "recentTeamId",
};

export const USER_ACCESS_ARRAY = [
  "e0h00op@lab.wal-mart.com",
  "e0h00op@homeoffce.wal-mart.com",
  "lb-a0s05q3-4@lab.wal-mart.com",
  "a0s05q3@homeoffice.wal-mart.com",
  "s0k02f8@homeoffice.wal-mart.com",
  "lb-s0k02f8@homeoffice.wal-mart.com",
];

export const FEATURE = {
  ZOOM_CAMPAIGNS: "zoom_campaigns", // not used
  SHOW_TEAM_SETTINGS: "show_team_settings",
  HIDE_TEAM_NAME: "hide_team_name",
  CREATE_TEAM: "create_team",
  CREATE_FOLDER: "create_folder",
  TEMPLATE_EDITOR_V2: "template_editor_v2",
  TEST_SEND: "test_send",
  VALIDATE_FROM: "validate_from_field_on_save",
  COMMS_TEMPLATES: "comms_templates",
  GT_TEMPLATES: "global_tech_templates",
  SAMS_TEMPLATES: "sams_templates",
  NEW_IMAGE_UIDS: "new_image_uids",
  REMOVE_LIST_MARGIN: "remove_list_margin",
  IMAGE_STUDIO: "image_studio",
  TEAM_TEMPLATES: "team_templates",
  EMAIL_ATTACHMENTS: "email_attachments",
  ZOOM_TEAMS_TEST_SEND: "zoom_teams_test_send",
  ADVANCED_TRACKING: "advanced_tracking",
  USE_FROM_DISPLAY_NAME: "from_display_name",
  HELP_SECTION: "help_section",
  FAQ: "faq_section",
  REPORT_SECTION: "report_section",
  MEDIA_LIBRARY: "media_library_section",
  CAMPAIGN_EXECUTIONS_DETAIL_DATA: "campaign_executions_detail_data",
  CAMPAIGN_DETAIL_DATA: "campaign_detail_data",
  SHOW_ALERT_BANNER: "show_alert_banner",
  SEND_NOTIFICATION: "send_notification",
  MAIL: 'mail_channel',
  TEAMS: 'teams_channel',
  ZOOM: 'zoom_channel',
  EXTERNAL_USERS_CHECK: "enable_unsubscribe_check",
  USE_V1: "enable_spark_notifications_v1",
};

export const PAGES = {
  CAMPAIGN: {
    value: 0,
    label: "Messages",
    route: ROUTES.FOLDERS,
  },
  REPORT: {
    value: 1,
    label: "Report",
    route: ROUTES.REPORT,
    toggle: FEATURE.REPORT_SECTION,
  },
  TEMPLATE: {
    value: 2,
    label: "Template",
    route: ROUTES.TEMPLATES,
  },
  MEDIA_LIBRARY: {
    value: 3,
    label: "Media library",
    route: ROUTES.MEDIA_LIBRARY,
    toggle: FEATURE.MEDIA_LIBRARY,
  },
  HELP: {
    value: 4,
    label: "Help",
    route: ROUTES.HELP,
    toggle: FEATURE.HELP_SECTION,
  },
};

export const PAGE_TITLES = {
  HOME: 'Home Page',
  FOLDER: 'Folder List Page',
  CAMPAIGN: 'Campaign List Page',
  CAMPAIGN_EDIT_PAGE: 'Campaign Edit Page',
  CAMPAIGN_VIEW_PAGE: 'Campaign View Page',
  FOLDER_CREATE: 'Create Folder Page',
  FOLDER_UPDATE: 'Update Folder Page',
  REPORT: 'Report Page',
  TEMPLATE: 'Template List Page',
  TEMPLATE_EDIT_PAGE: 'Template Edit Page',
  MEDIA_LIBRARY: 'Media Library List Page',
  HELP: 'Help Page',
  TEAMS_EDIT_PAGE: 'Teams Edit Page',
  MEDIA_FOLDER: 'Media Library Folder List Page',
  ERROR: 'Error Page'
}

export const EVENTS = {
  CREATE_TEMPLATE : {
    SAVE_TEMPLATE: 'Save Template - Success Event',
    CREATE_TEMPLATE: 'Create Template Btn Clicked',
  },
  MEDIA_LIBRARY : {
    UPLOAD_FILES: 'Upload Files - Success Event',
    DOWNLOAD_FILES: 'Download Files - Success Event',
    VIEW_FILE: 'View File Btn Clicked',
    RENAME_FILE: 'Rename File - Success Event',
    DELETE_FILES: 'Delete Files - Success Event',
    FILE_VIEW_TYPE: 'File View Type Changed'
  }, 
  ERRORS : {
    GENERIC_ERROR: 'Error Event'
  },
  TEAM: {
    TEAM_ACTIVITY: "Team Activity",
  },
  USER_INTERACTION: 'User Interaction Event'
}

export const EVENT_TYPES = {
  COMMON: {
    EMAIL_VALIDATION: 'email-validation-error-trigger'
  },
  MEDIA_LIBRARY: {
    UPLOAD_FILES: 'media-library-upload-file-event-trigger',
    DOWNLOAD_FILES: 'media-library-download-file-event-trigger',
    VIEW_FILE: 'media-library-view-file-event-trigger',
    RENAME_FILE: 'media-library-rename-file-event-trigger',
    DELETE_FILES: 'media-library-delete-file-event-trigger',
    FILE_VIEW_TYPE: 'media-library-file-view-change-event-trigger'
  },
  CREATE_MESSAGE_ERRORS: {
    CSV_INVALID_HEADERS: 'create-message-flow-invalid-csv-headers-error-trigger',
    GET_CAMPAIGN_ERROR: 'create-message-flow-get-campaign-api-error-trigger',
    SAVE_CAMPAIGN_ERROR: 'create-message-flow-save-campaign-api-error-trigger',
    SEND_CAMPAIGN_ERROR: 'create-message-flow-send-campaign-api-error-trigger',
    SCHEDULE_CAMPAIGN_ERROR: 'create-message-flow-schedule-campaign-api-error-trigger',
    SEND_TEST_CAMPAIGN_ERROR: 'create-message-flow-send-test-campaign-api-error-trigger',
    SAVE_TEMPLATE_ERROR: 'save-template-api-error-trigger',
    DUPLICATE_CAMPAIGN_ERROR: 'create-message-flow-duplicate-campaign-api-error-trigger',
    MOVE_CAMPAIGN_ERROR: 'create-message-flow-move-campaign-api-error-trigger',
    DELETE_CAMPAIGN_ERROR: 'create-message-flow-delete-campaign-api-error-trigger',
    GET_TEAM_STATS_ERROR: 'create-message-flow-fetch-team-stats-api-error-trigger',
    GET_TEAM_EVENTS_ERROR: 'create-message-flow-fetch-team-events-api-error-trigger'
  },
  TEAM_ACTIVITY: {
    SEND_MESSAGE: 'message-send-activity-trigger'
  },
  USER_INTERACTION: {
    ADD_MEMBER: 'add-member-click-trigger',
    EDIT_TEAM_SETTINGS: 'edit-team-settings-trigger',
    ENTER_RECIPIENTS_MANUALLY: 'enter-recipients-manually-trigger',
    CSV_UPLOAD: 'csv-upload-trigger',
    IMPORT_TEMPLATE: 'import-template-trigger',
    EXPORT_TEMPLATE: 'export-template-trigger',
    STANDARD_CAMPAIGN: 'standard-campaign-selected-trigger',
    ADVANCED_CAMPAIGN: 'advanced-campaign-selected-trigger',
    TRACKING_CONFIG: 'tracking-config-checkbbox-trigger',
    SEND_CAMPAIGN: 'send-campaign-button-click-trigger'
  }
}

export const TIME_ZONE = moment.tz.guess();
export const TIME_ZONE_ABBR = moment().tz(moment.tz.guess()).format("z");

export const SEARCH = {
  DELAY_MS: 300,
  MIN_CHAR_LENGTH: 2,
  EMPTY_RESULTS_DROPDOWN_MESSAGE: { message: "No matches found" },
  ERROR_DROPDOWN_MESSAGE: { message: "There was an error in fetching results" },
  // Incase of changing MAX_EMAIL_LIMIT_ALLOWED value, Please update the value in ERROR_MSG.EMAIL_LIMIT_REACHED as well
  MAX_EMAIL_LIMIT_ALLOWED: 100,
};

export const KEYBOARD_KEYS = {
  TAB: "Tab",
};

export const DATATYPE = {
  STRING: "string",
  OBJECT: "object",
  NUMBER: "number",
  BOOLEAN: "boolean",
};

// Template autosave duration in milliseconds
export const TEMPLATE_AUTOSAVE_DURATION = process.env.NODE_ENV === "test" ? 10 : 120000;
export const TEMPLATE_AUTOSAVE_SAVE_BTN_TEXT = "Saving";
export const TEMPLATE_AUTOSAVE_LOCALSTORAGE_KEY = "unsavedChanges";

export const ACCEPTED_FILES = ".jpg,.jpeg,.png,.gif";
export const REVIEW_STEP_INDEX = {
  CHANNEL_MAIL : 5,
  CHANNEL_TEAMS : 4
};
